import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { AuthInvite } from '../models/auth-statements';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthInvitesEntityService extends BaseEntityService<AuthInvite> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'authInvite', plural: 'authInvites', url: 'auth-invites' },
      routerParamsService
    );
    this.nameId = 'authInviteId';
  }

  save(values: any): Observable<AuthInvite> {
    if (!!values['phone']) {
      values['phone'] = `+55${ values['phone'] }`;
    }
    return this.add(values);
  }
}
